<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <!-- <v-menu
      v-if="isMobile"
      offset-x
      right
    >
      <template #activator="{ on, attrs }">
        <v-icon
          dark
          large
          color="#37474F"
          v-bind="attrs"
          v-on="on"
        >
          mdi-menu
        </v-icon>
      </template>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getProfileDetails()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-account
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              My Profile
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getOrganizationDetails()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-domain
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Organization
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-navigation-drawer
      v-if="!isMobile"
      permanent
      color="#ECEFF1"
      expand-on-hover
      absolute
      bottom
      floating
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="nav-title-style">
              <v-list-item-icon>
                <v-icon
                  style="font-size:25px;color:#37474F;font-weight:bold;"
                >
                  mdi-account-cog
                </v-icon>
              </v-list-item-icon>
              Profile
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <br>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getProfileDetails()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-account
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              My Profile
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getOrganizationDetails()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-domain
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Organization
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <div>
      <user-profile v-if="showProfileDetails" />
      <organization-details v-if="showOrganizationDetails" />
    </div> -->
    <h2 class="title-style">
      Profile
    </h2>
    <v-row class="mt-3">
      <v-col cols="12">
        <v-tabs v-model="tab">
          <v-tab>
            <v-icon class="icon-style">
              mdi-account
            </v-icon>
            My Profile
          </v-tab>
          <v-tab>
            <v-icon class="icon-style">
              mdi-domain
            </v-icon> Organization
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <user-profile />
          </v-tab-item>
          <v-tab-item>
            <organization-details />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserProfile from './UserProfile.vue';
import OrganizationDetails from './organization/OrganizationDetails.vue';

 export default {
    name: 'ProfileSettings',
    components: {
      'user-profile': UserProfile,
      'organization-details': OrganizationDetails,
    },
    data: () => ({
      selectedItem: -1,
      showProfileDetails: true,
      showOrganizationDetails: false,
      tab: null,
    }),
    computed: {
      isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
    },
    mounted () {
      this.selectedItem = 0;
    },
    methods: {
      getProfileDetails () {
        this.showOrganizationDetails = false;
        this.showProfileDetails = true;
      },
      getOrganizationDetails () {
        this.showProfileDetails = false;
        this.showOrganizationDetails = true;
      },
    },
  };

</script>
<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
.title-style {
  color: #37474f;
  margin-top: 30px;
  font-size: 36px;
  font-weight: 600;
}
.icon-style{
  margin-right: 5px;
}
</style>
